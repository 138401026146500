import React, { useState } from "react";
import userIcon from "../../assets/user_icon.png";
import settingsIcon from "../../assets/setting.png";
import aboutIcon from "../../assets/info.png";
import supportIcon from "../../assets/support.png";
import logoutIcon from "../../assets/Exit.png";

import { useDispatch, useSelector } from "react-redux";
import { LOG_OUT } from "../../redux/action";
import { useNavigate } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import SettingsModal from "./SettingsModal";
import AboutModal from "./AboutModal";
import SupportModal from "./SupportModal";
import LogoutModal from "./LogoutModal";

const UserMenu = () => {

  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const user = useSelector((state) => state.user);

  return (
    <>
      <div className="dropdown">
        {/* User icon to toggle dropdown */}
        <button
          className=""
          style={{ backgroundColor: "transparent", border: "none" }}
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="">
            <div className="border border-1 border-Color icon-container">
              {!user?.profileImage  && <img
                src={userIcon}
                width={18}
                height={18}
                alt="User icon"
                className="object-fit-contain"
                style={{ width: "100%", marginTop: "12px" }}
              />}
              { user?.profileImage && <img
                src={user?.profileImage}
                alt="User icon"
                className="user-icon" />
            }
            </div>
          </div>
        </button>

        {/* Dropdown Menu */}
        <ul
          className="dropdown-menu dropdown-menu-end shadow p-3 rounded-5 user-menu"
          aria-labelledby="dropdownMenuButton"
        >
          {/* Profile Section */}
          <li className="text-center">
          {!user?.profileImage  &&  <div className="border border-1 border-Color icon-container" style={{margin: "auto"}}><img
                src={userIcon}
                width={18}
                height={18}
                alt="User icon"
                className="object-fit-contain"
                style={{ width: "100%", marginTop: "12px" }}
              /></div>}
            {user?.profileImage && <img
              src={user?.profileImage || userIcon}
              alt="User icon"
              className="rounded-circle border mb-2"
              width="60"
              height="60"
            />}
            <h6 className="mb-3" style={{ fontWeight: 700, fontSize: "15px" }}>
              {user?.fullName}
            </h6>
          </li>

          <li className="menu-item">
            <a className="dropdown-item" href="#" onClick={() => setShowSettingsModal(true)}>
              <img
                src={settingsIcon}
                width={20}
                height={20}
                style={{ marginRight: "5px" }}
              />
              Settings
              <BsChevronRight
                style={{
                  fontSize: "15px",
                  position: "absolute",
                  right: "10px",
                  top: "8px"
                }}
              />
            </a>
          </li>
          <li className="menu-item">
            <a className="dropdown-item" href="#" onClick={() => setShowSupportModal(true)}>
              <img
                src={supportIcon}
                width={20}
                height={20}
                style={{ marginRight: "5px" }}
              />{" "}
              Support
              <BsChevronRight
                style={{
                  fontSize: "15px",
                  position: "absolute",
                  right: "10px",
                  top: "8px"
                }}
              />
            </a>
          </li>
          <li className="menu-item">
            <a className="dropdown-item" href="#" onClick={() => setShowAboutModal(true)}>
              <img
                src={aboutIcon}
                width={20}
                height={20}
                style={{ marginRight: "5px" }}
              />{" "}
              About
              <BsChevronRight
                style={{
                  fontSize: "15px",
                  position: "absolute",
                  right: "10px",
                  top: "8px"
                }}
              />
            </a>
          </li>
          <li className="menu-item-break"></li>

          <li className="menu-item">
            <a className="dropdown-item text-danger" onClick={() => setShowLogoutModal(true)} href="#">
              <img
                src={logoutIcon}
                width={20}
                height={20}
                style={{ marginRight: "5px" }}
              />{" "}
              Logout
              <BsChevronRight
                style={{
                  color: "rgba(231, 76, 60, 1)",
                  fontSize: "15px",
                  position: "absolute",
                  right: "10px",
                  top: "8px"
                }}
              />
            </a>
          </li>
        </ul>
      </div>
      {showSettingsModal && (
        <SettingsModal
          show={showSettingsModal}
          onClose={() => setShowSettingsModal(false)}
        />
      )}
      {showAboutModal && (
        <AboutModal
          show={showAboutModal}
          onClose={() => setShowAboutModal(false)}
          />
      )}
      {showSupportModal && <SupportModal show={showSupportModal} onClose={() => setShowSupportModal(false)}></SupportModal>}
      {showLogoutModal && <LogoutModal show={showLogoutModal} onClose={() => setShowLogoutModal(false)}></LogoutModal>}
    </>
  );
};

export default UserMenu;
