import React from 'react';

const NormalInput = ({
  field, 
  form: { touched, errors },
  ...props
}) => {
  const { label, options, disabled, className, placeholder, type = "text" } = props;

  return (
    <div className={className} style={props.style}>
      {label && <div className="f-3 color-dark_gray mb-1 mt-1 px-1">{label}</div>}
      {type === "textarea" ? (
        <textarea
          {...field}
          className={`py-3 w-100 f-3 inputPadding border-Color border  ${className}`}
          disabled={disabled}
          placeholder={placeholder}
          style={{ borderRadius: "16px", height: "150px" }}
        />
      ) : type === "select" ? (
        <select
          {...field}
          className={`py-3 w-100 f-3 inputPadding border-Color border ${className}`}
          disabled={disabled}
          style={{ borderRadius: "16px" }}
        >
          {options?.map((item, k) => (
            <option key={k} value={item.value || item}>
              {typeof item === "object" ? item?.title : item}
            </option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          {...field}
          className={`py-3 w-100 f-3 inputPadding border-Color border ${className}`}
          disabled={disabled}
          placeholder={placeholder}
          style={{ borderRadius: "16px" }}
        />
      )}
      {touched[field.name] && errors[field.name] && (
        <div className="text-danger">{errors[field.name]}</div>
      )}
    </div>
  );
};

export default NormalInput;