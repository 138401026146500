import { useState } from "react";
import { Button, Card, CardBody, CardHeader, Label, Input, Form, FormGroup } from "reactstrap";
import NormalInput from "../../components/inputs/NormalInput";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftShort, BsUpload } from "react-icons/bs";
import { CiLock } from "react-icons/ci";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import allset from "../../assets/allset.png";
import WhereIsMyCodeModal from "./WhereIsMyCode";
import Members from "../../components/globals/Members";


const SetupAccount = () => {
  const [flowAt, setFlowAt] = useState(0);
  const [showCodeInfoModal, setShowCodeInfoModal] = useState()
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const processImage = () => {
    console.log("processing image")
    setFlowAt(5)
  }

  const association = { name: "Hidden Hills HOA" }

  const allSet = () => {
    return (
      <>
        <CardHeader
          className="text-center bg-transparent border-none  mt-3"
          tag={"div"}
        >
          <h4 className="f-6 "><BsArrowLeftShort 
                onClick={() => setFlowAt(4)}
              style={{ width: "35px", height: "35px", position: "absolute", left: "25px" }} />
            All Set!</h4>
            <small className="d-block">
            Your account has been successfully created. Welcome to Heynay!</small>
        </CardHeader>
        <CardBody>
          <div className="text-center mb-3">
          <img src={allset} alt="All set" className="img-fluid" style={{width: "200px"}}/>
          </div>
        <div className=" d-flex flex-column align-items-center">
            <Button
              onClick={() => navigate("/homefeed")}
              className="border-none btn-blue full-width py-3 mt-3 mb-2 mx-2 font-weight-700"
            >
              Next
            </Button>

          </div>
        </CardBody>
      </>
    );
  }

  const inviteMembers = () => {
    return (
      <>
        <CardHeader
          className="text-center bg-transparent border-none  mt-3"
          tag={"div"}
        >
          <h4 className="f-6 "><BsArrowLeftShort 
                onClick={() => setFlowAt(4)}
              style={{ width: "35px", height: "35px", position: "absolute", left: "25px" }} />
            Invite Household Members</h4>
            <small className="d-block">
            Invite your household members (family, renters/tenants, etc.) to ensure they stay informed and up-to-date on what's going on in your association.
              </small>
        </CardHeader>
        <CardBody>
          <Members/>
        <div className=" d-flex flex-column align-items-center">
            <Button
              onClick={() => setFlowAt(6)}
              className="border-none btn-blue full-width py-3 mt-3 mb-2 mx-2 font-weight-700"
            >
              Next
            </Button>

          </div>
        </CardBody>
      </>
    );
  }

  const renderHoa = () => {
    return (
      <>
        <CardHeader
          className="text-center bg-transparent border-none mb-3 mt-3"
          tag={"div"}
        >
          <h4 className="f-6 ">
            Code Verified!</h4>
          <small className="d-block">
            You have successfully joined:
          </small>
        </CardHeader>
        <CardBody>
          <p className="text-center font-weight-700">{association.name} 🏡</p>
          <img className="img-fluid" src="https://placehold.co/544x366.png" alt="Association image"
            style={{ borderRadius: "24px" }}
          />
          <div className=" d-flex flex-column align-items-center">
            <Button
              onClick={() => setFlowAt(3)}
              className="border-none btn-blue full-width py-3 mt-3 mb-2 mx-2 font-weight-700"
            >
              Continue
            </Button>

          </div>
        </CardBody>
      </>
    );
  }

  const profile = () => {
    return (
      <>
        <CardHeader
          className="text-center bg-transparent border-none  mt-3"
          tag={"div"}
        >
          <h4 className="f-6 "><BsArrowLeftShort 
                onClick={() => setFlowAt(3)}
              style={{ width: "35px", height: "35px", position: "absolute", left: "25px" }} />
            Profile Picture</h4>
            <small className="d-block">
                Upload your profile picture.
              </small>
        </CardHeader>
        <CardBody>
          <Form className="mt-2 mb-3">
            <div className="text-center"
                          style={{
                            margin: "auto",
                            width: 140,
                            height: 140,
                            borderColor: "rgba(1, 144, 210, 1)",
                            borderWidth: 1,
                            borderStyle: "dashed",
                            borderRadius: 70,
                            backgroundColor: "rgba(248, 248, 248, 1)"
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "45px",
                              fontSize: "20px"
                            }}
                          >
                            <BsUpload style={{ color: "rgba(1, 144, 210, 1)" }} />
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: 500,
                              fontSize: 13,
                              color: "rgba(1, 144, 210, 1)"
                            }}
                          >
                            Upload a photo
                          </div>
                        </div>
          </Form>
          <div className=" d-flex align-items-center">
             <Button
                        onClick={() => setFlowAt(5)}
                        style={{
                          backgroundColor: "rgba(245, 245, 245, 1)",
                          fontWeight: 700,
                          fontSize: 16,
                          padding: "14px 20px",
                          borderColor: "unset",
                          border: 'none',
                          color: 'rgba(231, 76, 60, 1)'
                        }}
                        className="w-100 rounded-pill"
                      >
                        Skip
                      </Button>
            <Button
              onClick={processImage}
              className="border-none btn-blue full-width py-3 mt-3 mb-2 mx-2 font-weight-700"
            >
              Next
            </Button>

          </div>
        </CardBody>
      </>
    );
  }

  const setupPassword = () => {
    return (
      <>
        <CardHeader
          className="text-center bg-transparent border-none mb-3 mt-3"
          tag={"div"}
        >
          <h4 className="f-6 ">
            Setup a Password</h4>
        </CardHeader>
        <CardBody>
          <Form className="mt-4">
            <FormGroup>
              <Label for="password" className="textGray mb-1 ps-3">
                Password
              </Label>
              <div className="position-relative w-100">
                <CiLock
                  className="position-absolute lock-icon text-dark fs-5"
                  style={{
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)"
                  }}
                />
                <Input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Minimum 8 characters..."
                  className="form-control-lg w-100 borderLight rounded-4 ps-5 custom-placeholder"
                  style={{ paddingLeft: "45px" }}
                />
                {/* Eye Icon for toggling password visibility */}
                <div
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                >
                  {showPassword ? (
                    <IoEyeOffOutline size={24} />
                  ) : (
                    <IoEyeOutline size={24} />
                  )}
                </div>{" "}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="passwordConfirm" className="textGray mb-1 ps-3">
                Confirm Password
              </Label>
              <div className="position-relative w-100">
                <CiLock
                  className="position-absolute lock-icon text-dark fs-5"
                  style={{
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)"
                  }}
                />
                <Input
                  name="passwordConfirm"
                  type={showPassword ? "text" : "password"}
                  placeholder="Minimum 8 characters..."
                  className="form-control-lg w-100 borderLight rounded-4 ps-5 custom-placeholder"
                  style={{ paddingLeft: "45px" }}
                />
                {/* Eye Icon for toggling password visibility */}
                <div
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                >
                  {showPassword ? (
                    <IoEyeOffOutline size={24} />
                  ) : (
                    <IoEyeOutline size={24} />
                  )}
                </div>{" "}
              </div>
            </FormGroup>
          </Form>
          <div className=" d-flex flex-column align-items-center">
            <Button
              onClick={() => setFlowAt(4)}
              className="border-none btn-blue full-width py-3 mt-3 mb-2 mx-2 font-weight-700"
            >
              Next
            </Button>

          </div>
        </CardBody>
      </>
    );
  }

  const renderComponent = () => {
    switch (flowAt) {
      case 0:
        return (
          <>
            <CardHeader
              className="text-center bg-transparent border-none mb-3"
              tag={"div"}
            >
              <h4 className="f-7 m-0 mt-3 font-32px">Set Up Account</h4>

            </CardHeader>
            <CardBody>
              <h6 className="text-center f-6 m-0 mt-1">Let’s Get Started</h6>
              <div className="mb-6 d-flex flex-column align-items-center">
                <Button
                  onClick={() => setFlowAt(1)}
                  className="border-none btn-blue button-width py-3 mt-3 mb-2 mx-2 f-7"
                >
                  Set Up Account
                </Button>
                <small className="d-block text-center mx-3 opacity-50 small-width ">
                  By creating an account. you agree to our
                  Terms of Service and
                  Privacy Policy
                </small>
              </div>
              <div className="d-flex flex-column align-items-center mt-2">
                <small className="text-center d-block mt-3" style={{ marginBottom: "-6px" }}>
                  Already have an account?
                </small>
                <Button className="button-width mx-2 btn-grey py-3 border-05 border-grey  text-black mt-3 f-7" onClick={() => navigate("/")}>
                  Login to your Account
                </Button>
              </div>
            </CardBody>
          </>
        );

      case 1:
        return (
          <>
            <CardHeader
              className="text-center bg-transparent border-none mb-3 mt-3"
              tag={"div"}
            >
              <h4 className="f-6 "><BsArrowLeftShort 
                onClick={() => setFlowAt(0)}
              style={{ width: "35px", height: "35px", position: "absolute", left: "25px" }} />
                Verification Code</h4>
              <small className="d-block">
                Please enter your verification code to get started.
              </small>
            </CardHeader>
            <CardBody>
              <Form className="mt-4">
                <Label for="verificationCode" className="textGray mb-1 ps-3">
                  Verification Code
                </Label>
                <Input
                  type="text"
                  id="verificationCode"
                  placeholder="- - - - - -"
                  className="form-control-lg w-100 borderLight rounded-4"
                />
                <p onClick={() => setShowCodeInfoModal(true)}  className="text-center mt-1" style={{ fontWeight: 700, fontSize: "15px", cursor: "pointer" }}>Where is my code?</p>
              </Form>
              <div className=" d-flex flex-column align-items-center">
                <Button
                  onClick={() => setFlowAt(2)}
                  className="border-none btn-blue full-width py-3 mt-3 mb-2 mx-2 font-weight-700"
                >
                  Next
                </Button>

              </div>
            </CardBody>
          </>
        );
      case 2:
        return renderHoa();
      case 3:
        return setupPassword();
      case 4:
        return profile();  
      case 5:
        return inviteMembers();  
      case 6:
        return allSet();  
    }
  };

  return (
    <div
      style={{ maxWidth: flowAt === 0 ? "416px" : "540px" }}
      className="container d-flex align-items-center justify-content-center vh-100"
    >
      <Card style={{ borderRadius: "48px", width: flowAt === 0 ? "416px" : "540px" }} className={`py-3  {flowAt === 0 ? "desktop-height" : ""} box-shadow`}>
        {renderComponent()}
      </Card>
      <WhereIsMyCodeModal show={showCodeInfoModal} onClose={() => setShowCodeInfoModal(false)}></WhereIsMyCodeModal>
    </div>
  );
};

export default SetupAccount;
