import { useState } from "react";
import { BiUser } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import logoImg from "../../assets/logo.png";
import icon from "../../assets/Icon.png";
import userIcon from "../../assets/user_icon.png";
import { PiBellBold } from "react-icons/pi";
import { RxHamburgerMenu } from "react-icons/rx";
import {
  Nav,
  Navbar,
  Button,
  NavItem,
  Collapse,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";
import UserMenu from "./UserMenu";
import { Link } from "react-router-dom";

const Header = ({ onLogout, onLinkClick }) => {
  const [isOpen, setisOpen] = useState(false);
  const { user, token } = useSelector((state) => state);
  const { theme } = useSelector((s) => s?.appSettings ?? {});

  console.log("token", token)

  return (
    <Navbar
      className={`header ${theme} py-1 px-md-3 border-Color`}
      expand={"md"}
      style={{ zIndex: 10}}
    >
      <NavbarBrand className="d-flex align-items-center gap-2">
        <Link href="/"><img
          alt=""
          width={140}
          height={70}
          src={logoImg}
          className="object-fit-contain"
        /> </Link>
      </NavbarBrand>
      <NavbarToggler onClick={() => setisOpen((prev) => !prev)}>
        {!isOpen ? <RxHamburgerMenu size={20} /> : <RxCross2 size={20} />}
      </NavbarToggler>
      <Collapse navbar isOpen={isOpen}>
        <Nav navbar className="full-width justify-content-end gap-2">
          {token && (
            <div className="d-flex align-items-center justify-content-between gap-3 my-3">
              <UserMenu/>
            </div>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;
