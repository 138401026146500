import React, { useState } from "react";
import {  BsPaperclip} from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import {
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { CiLock } from "react-icons/ci";
import { Field } from "formik";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { LOG_OUT } from "../../redux/action";
import { useNavigate } from "react-router-dom";

const LogoutModal = ({ show, onClose }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
  const logout = () => {
    dispatch({ type: LOG_OUT });
    navigate("/");
  }
  return (
    <Modal centered isOpen={show} toggle={onClose} contentClassName="rounded-5">
      {/* Modal Header */}
      <div className="d-flex justify-content-between align-items-center pt-4 px-4 pb-0">
        <h4 className="font-32px f-7">Logout?</h4>
        <IoIosClose
          size={40}
          onClick={onClose}
          className="rounded-circle border bg-light p-2 cursor-pointer"
        />
      </div>

      {/* Scrollable Content (Including Button) */}
      <ModalBody className="p-4">
        <p style={{"textAlign": "center", marginTop: "-10px"}}>Are you sure you want to logout your account?</p>
        <div className="mt-4 d-flex justify-content-between">
        <Button
            onClick={onClose}
            style={{
              backgroundColor: "rgba(245, 245, 245, 1)",
              fontWeight: 700,
              fontSize: 16,
              padding: "14px 20px",
              borderColor: "unset",
              border: 'none',
              color: 'rgba(51, 51, 51, 1)'
            }}
            className="w-100 rounded-pill"
          >
            Cancel
          </Button>
          <Button
            onClick={logout}
            style={{
              backgroundColor: "rgba(231, 76, 60, 1)",
              fontWeight: 700,
              fontSize: 16,
              padding: "14px 20px",
              borderColor: "unset"
            }}
            className="w-100 rounded-pill"
          >
            Logout
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LogoutModal;
