import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { IoIosClose } from "react-icons/io";
import { TfiAngleRight } from "react-icons/tfi";

const CategorySelectionModal = ({
  isOpen,
  toggle,
  categories,
  handleCategorySelect,
  title, // Accept title as a prop
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="rounded-5"
      style={{ maxWidth: "650px" }}
    >
      <div className="d-flex align-items-center justify-content-between pt-4 px-4 pb-0">
        <h3 className="font-32px f-7" style={{ color: "rgba(51, 51, 51, 1)" }}>
          {title} {/* Use the title prop here */}
        </h3>
        <IoIosClose
          size={40}
          onClick={toggle}
          className="rounded-circle border bg-light p-2 cursor-pointer"
        />
      </div>
      <ModalBody className="p-4">
        <h6 className="f-7" style={{ fontSize: "18px" }}>
          Select a Post Type:
        </h6>
        {categories.map((category) => (
          <div
            className="p-0 mb-2 borderRadius-16 text-dark"
            style={{
              height: "100px",
              borderStyle: "solid",
              borderColor: "rgba(197, 197, 197, 1)",
              borderWidth: "0.5px",
            }}
            key={category.label}
          >
            <div
              className="d-flex align-items-center justify-content-between borderRadius-16 bg-light-gray p-3 h-100 cursor-pointer"
              onClick={() => handleCategorySelect(category)}
            >
              <div className="d-flex align-items-center">
                <div className="p-1">{category.icon}</div>
                <div className="ms-2">
                  <h6 className="mb-0 f-6 text-black">{category.label}</h6>
                  <small className="text-muted">{category.description}</small>
                </div>
              </div>
              <TfiAngleRight size={18} />
            </div>
          </div>
        ))}
      </ModalBody>
    </Modal>
  );
};

export default CategorySelectionModal;