import "./style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import "slick-carousel/slick/slick-theme.css";
import { Card, Col, Row, Button } from "reactstrap";
import ProfileCard from "../ProfileCard/ProfileCard";
import bgImgeDummy from "../../assets/noImgFound.png";
import CreatePostModal from "../ModalComponent/CreatePostModal";

const HomerFeedCard = ({
  post,
  name,
  avatar,
  btnIcon,
  heading,
  content,
  BlogPost,
  setPosts,
  timestamp,
  buttonText,
  setAllPosts,
  allCategories,
  onButtonClick,
}) => {
  const [otherModalOpen, setOtherModalOpen] = useState(false);
  const postDetailModel = () => {
    setOtherModalOpen(!otherModalOpen);
  };

  const handleSlideClick = (file) => {
    postDetailModel();
  };

  const sliderSettings = {
    dots: post?.files?.length >= 2,
    arrows: post?.files?.length >= 2,
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const HomeFeedsSliderSettings = {
    dots: post?.files?.length >= 2,
    arrows: post?.files?.length >= 2,
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  console.log("AllPost", setAllPosts);
  return (
    <>
      <Card
        className="heynay-radius my-2 Color-dark cursor-pointer"
        // onClick={() => handleSlideClick()}
      >
        <ProfileCard
          post={post}
          name={name}
          avatar={avatar}
          btnIcon={btnIcon}
          setPosts={setPosts}
          timestamp={timestamp}
          buttonText={buttonText}
          setAllPosts={setAllPosts}
          allCategories={allCategories}
          onButtonClick={onButtonClick}
        />
        <div className="cursor-pointer w-100 p-4 pt-2 pb-2">
          <h2 className="mt-2 f-6 text-black font-20px">{heading}</h2>
          <p className="mt-2 text-black font-16px">{content}</p>

          <div className="align-items-center h-100">
            {post.files.length > 0 ? (
              <Slider {...{ ...HomeFeedsSliderSettings, arrows: false, dotsClass: "slick-dots" }}>
                {post.files.map((file, index) => (
                  <div
                    key={index}
                    className="img-Container-Height d-flex justify-content-center w-100"
                  >
                    <img
                      onClick={() => handleSlideClick()}
                      src={file}
                      alt={`Slide ${index + 1}`}
                      style={{
                        width: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                        borderRadius: "12px"
                      }}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              ""
            )}
          </div>
        </div>
      </Card>

      <CreatePostModal
        contentClassName="bg-white border-0"
        isOpen={otherModalOpen}
        toggle={postDetailModel}
        headerTitle="Create a Post"
        maxWidth="1080px"
        headerClass="d-none"
        bodyClass=""
      >
        <Row className="h-100">
          <Col
            md={8}
            className="bg-transparent position-relative order-md-1 order-2 px-md-3 px-0 img-Container-Height"
          >
            <div className="align-items-center mx-md-3  mx-0 h-100">
              {post.files.length > 0 ? (
                <Slider {...sliderSettings}>
                  {post.files.map((file, index) => (
                    <div
                      key={index}
                      className="img-Container-Height d-flex align-items-center justify-content-center"
                    >
                      <img
                        src={file}
                        alt={`Slide ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="d-flex align-items-center justify-content-center h-100 position-relative">
                  <img
                    src={bgImgeDummy} // Replace with the path to your dummy image
                    alt="No data available"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                  <div
                    className="position-absolute w-100 h-100"
                    style={{
                      top: 0,
                      left: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  ></div>
                </div>
              )}
            </div>
          </Col>
          <Col md={4} className="bg-white order-md-2 order-1 rounded-md">
            <div className="pt-3">
              <div
                className="position-relative cursor-pointer"
                onClick={postDetailModel}
              >
                <span
                  className="rounded-circle p-1 bg-light closeIcon"
                  style={{ right: 0 }}
                >
                  <IoClose className="text-dark" />
                </span>
              </div>
              <ProfileCard
                name={name}
                avatar={avatar}
                btnIcon={btnIcon}
                post={post.postId}
                timestamp={timestamp}
                buttonText={buttonText}
                allCategories={allCategories}
                onButtonClick={onButtonClick}
                detailModal={true}
              />
              <div className="px-3 mt-3">
                {" "}
                <h1 className="mt-2 text-black f-6 font-20px">{post.title}</h1>
                <p className="mt-2 text-black font-16px">{post.content}</p>
              </div>
            </div>
          </Col>
        </Row>
      </CreatePostModal>
    </>
  );
};

export default HomerFeedCard;
