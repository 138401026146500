import "./style.css";
import Toggle from "react-toggle";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import DropdownComponent from "../dropdown";
import { IoIosClose } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import { PiArrowLeft } from "react-icons/pi";
import NormalInput from "../inputs/NormalInput";
import { HiOutlinePhoto } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import StandardButton from "../buttons/StandardButton";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { deletePost, getPostList, updatePost } from "../../redux/action";
import {
  Col,
  Row,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { CATEGORIES } from "../../utils/constants";
import CategorySelectionModal from "../ModalComponent/CategorySelectionModal"

const ProfileCard = ({
  post,
  name,
  avatar,
  btnIcon,
  setPosts,
  timestamp,
  buttonText,
  setAllPosts,
  detailModal,
  allCategories,
  onButtonClick
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const token = useSelector((state) => state.token);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isChecked, setIsChecked] = useState(post.isPinned);
  const [currentIndex, setCurrentIndex] = useState(
    allCategories?.findIndex((cat) => cat?.label === post?.label) || 0
  );
  const [categoryLabel, setCategoryLabel] = useState(post.label);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  const handleArrowClick = () => {
    setShowModal(false);
    setIsCategoryModalOpen(true);
  };

  const handleCategorySelect = (category) => {
    setCategoryLabel(category.label);
    setCurrentIndex(allCategories.findIndex((cat) => cat.label === category.label));
    setIsCategoryModalOpen(false);
    setShowModal(true);
  };

  const handleShowModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleToggleChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleCloseModal = () => setShowModal(false);

  const DropdownArray = [
    {
      label: "Edit Post",
      onClick: () => handleShowModal("edit")
    },
    {
      label: "Delete Post",
      onClick: () => handleShowModal("delete")
    }
  ];
  const handleFileChange = (event, setFieldValue) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setFieldValue("files", selectedFiles);
  };

  const handleDeletePost = async (post) => {
    try {
      await deletePost(post.postId, token);
      toast.success("Post deleted successfully");
      const data = await dispatch(getPostList(token));
      setAllPosts(data.posts);
      setPosts(data.posts.slice(0, 5));
      setShowModal(false);
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.error("Error deleting post");
    }
  };

  const removeFile = (index, setFieldValue) => {
    setFiles((prevFiles) => {
      const newFiles = prevFiles.filter((_, i) => i !== index);
      setFieldValue("files", newFiles);
      return newFiles;
    });
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const data = {
        label: categoryLabel,
        content: values.content,
        title: values.heading,
        isPinned: values.isPinned
      };
      await updatePost(post.postId, data, token);
      toast.success("Post updated successfully");
      setCategoryLabel(data.label);
      handleCloseModal();
      const postData = await dispatch(getPostList(token));
      setAllPosts(postData.posts);
      setPosts(postData.posts.slice(0, 5));
    } catch (error) {
      console.error("Error updating post:", error);
      toast.error("Error updating post");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="rounded-4 ps-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center pt-4">
          <div
            className="rounded-circle me-2 bgProperties"
            style={{
              width: "50px",
              height: "50px",
              backgroundImage: `url(${avatar})`
            }}
          >
            {/* <img width={50} src={avatar} alt={`${name}'s avatar`} /> */}
          </div>
          <div>
            <p className="mb-0 f-5 color-dark_gray">{name}</p>
            <p className="mb-0 Color-light small">{timestamp}</p>
          </div>
        </div>
        {
          <DropdownComponent
            onClick={(event) => event.stopPropagation()}
            items={DropdownArray}
            buttonLabel={<BsThreeDots size={28} />}
            width={detailModal ? "70px" : "90px"}
            className="bg-transparent rounded-12 border-0 p-0 me-2 d-block text-secondary"
          />
        }
      </div>
      <StandardButton
        className="text-secondary CategoryBtn py-0 rounded-20"
        onClick={onButtonClick}
      >
        <span className="d-flex justify-content-between align-items-center py-1 text-dark">
          {btnIcon}
          <p className="mb-0 px-2 pt-1 pb-1 color-dark_gray">{buttonText}</p>
        </span>
      </StandardButton>
      <CategorySelectionModal
          isOpen={isCategoryModalOpen}
          toggle={() => setIsCategoryModalOpen(!isCategoryModalOpen)}
          categories={CATEGORIES}
          handleCategorySelect={handleCategorySelect}
          title="Edit Post" // Pass the title dynamically
        />
      <Modal
        isOpen={showModal}
        toggle={handleCloseModal}
        contentClassName="rounded-5"
        style={{
          maxWidth: modalContent === "edit" ? "650px" : "600px",
          overflow: "hidden"
        }}
      >
         <div
        className={`d-flex align-items-center justify-content-between pt-4 px-4 pb-0"`}
      >
            <h3 className="font-32px f-7" style={{ color: "rgba(51, 51, 51, 1)" }}>
              {modalContent === "edit" ? "Edit Post" : "Delete Post"}
            </h3>
            <IoIosClose
              size={40}
              onClick={handleCloseModal}
              className="rounded-circle border bg-light p-2 cursor-pointer"
            />
        </div>
        <ModalBody className="p-4">
          {modalContent === "edit" ? (
            <Formik
              initialValues={{
                label: categoryLabel,
                heading: post.title,
                content: post.content,
                files: [],
                isPinned: post.isPinned
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, isSubmitting }) => (
                <Form>
                  <PiArrowLeft
                    onClick={() => handleArrowClick(setFieldValue)}
                    className="cursor-pointer"
                  />
                  <StandardButton className="CategoryBtn text-dark ms-3 rounded-20">
                    <span className="d-flex align-items-center">
                      <p className="mb-0 px-2 d-flex align-items-center gap-2">
                        {allCategories[currentIndex].icon}
                        {allCategories[currentIndex].label}
                      </p>
                    </span>
                  </StandardButton>

                  <div>
                    <Field
                      name="heading"
                      type="text"
                      component={NormalInput}
                      label="Heading"
                      className="mb-2"
                      placeholder="Type here...."
                    />
                    <ErrorMessage
                      name="heading"
                      component="div"
                      className="text-danger"
                    />
                    <Field
                      name="content"
                      type="textarea"
                      component={NormalInput}
                      label=""
                      className="h-100 mb-2"
                      placeholder="Share with your HOA community"
                    />
                    <ErrorMessage
                      name="content"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <input
                    multiple
                    type="file"
                    className="d-none"
                    ref={fileInputRef}
                    onChange={(event) => handleFileChange(event, setFieldValue)}
                  />
                  <div
                    onClick={triggerFileInput}
                    className="border text-center d-flex align-items-center justify-content-center p-3 rounded-16 my-2"
                    style={{
                      cursor: "pointer",
                      backgroundColor: "rgba(245, 245, 245, 1)"
                    }}
                  >
                    <HiOutlinePhoto size={20} />
                    <span className="px-2">Photo</span>
                  </div>
                  <div className="d-flex flex-wrap">
                    {files.map((file, index) => (
                      <div
                        key={index}
                        className="position-relative mx-1 rounded-12 overflow-hidden"
                      >
                        <img
                          src={URL.createObjectURL(file)}
                          alt="Uploaded"
                          width={100}
                        />
                        <IoIosClose
                          size={20}
                          onClick={() => removeFile(index, setFieldValue)}
                          className="position-absolute top-0 end-0 bg-light rounded-circle p-1 m-1 cursor-pointer"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="my-3 d-flex align-items-center">
                    <Toggle
                      checked={isChecked}
                      onChange={(event) =>
                        handleToggleChange(event, setFieldValue)
                      }
                      icons={false}
                    />
                    <p className="m-0 ms-2 color-dark_gray">
                      Pin Post to top of Community Feed
                    </p>
                  </div>
                  <ModalFooter className="border-0">
                    <Row className="justify-content-center w-100">
                      <Col md={6} className="mb-3 order-md-first order-last">
                        <Button
                          className="p-3 rounded-4 w-100 btn btn-light border-0 color-dark_gray"
                          onClick={handleCloseModal}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Button
                          type="submit"
                          className="p-3 rounded-4 w-100 btn-blue border-0 f-6"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Saving..." : "Save Changes"}
                        </Button>
                      </Col>
                    </Row>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          ) : (
            <p className="text-center color-dark_gray">
              Are you sure you want to delete this post? You cannot undo this.
            </p>
          )}
        </ModalBody>
        <ModalFooter className="border-0">
          <Row className="justify-content-center w-100">
            <Col md={6}>
              {modalContent === "edit" ? null : (
                <Button
                  className="p-3 rounded-4 w-100 btn btn-light color-dark_gray border-0"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              )}
            </Col>
            <Col md={6}>
              {modalContent === "edit" ? null : (
                <Button
                  className="p-3 rounded-4 w-100 text-white border-0"
                  style={{ background: "#E74C3C" }}
                  onClick={() => handleDeletePost(post)}
                >
                  Delete Post
                </Button>
              )}
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProfileCard;
