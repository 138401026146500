import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Button, FormGroup, Label, Form, Tooltip } from "reactstrap";
import { Field, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { inviteMember, updateMember } from "../../redux/action";
import toast from "react-hot-toast";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const HouseholdMemberForm = ({ show, onClose, member, refreshMembers }) => {
  const [isHomeowner, setIsHomeowner] = useState(member?.type === "homeowner");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { token } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsHomeowner(member?.type === "homeowner");
  }, [member]);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const params = { ...values, type: isHomeowner ? "homeowner" : "tenant" };
      if (member) {
        await updateMember(token, member.id, params);
        toast.success("Member updated successfully.");
      } else {
        await inviteMember(token, params);
        toast.success("Member invited successfully.");
      }
      resetForm();
      onClose();
      refreshMembers();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <Modal centered isOpen={show} toggle={onClose} contentClassName="rounded-5">
      <div className="d-flex justify-content-between align-items-center pt-4 px-4 pb-0">
        <h4 className="font-32px f-7">{member ? "Edit Household Member" : "Invite Household Member"}</h4>
        <IoIosClose
          size={40}
          onClick={onClose}
          className="rounded-circle border bg-light p-2 cursor-pointer"
        />
      </div>
      <ModalBody className="p-4">
        <p style={{ textAlign: "center", marginTop: "-10px" }}>
          Please enter the name and email address of the household member.
        </p>
        <Formik
          initialValues={{
            fullName: member?.fullName || "",
            email: member?.email || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="fullName" className="textGray mb-1 ps-3">
                  Full Name
                </Label>
                <Field
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="Type here..."
                  className="form-control-lg w-100 borderLight rounded-4"
                />
                <ErrorMessage name="fullName" component="div" className="text-danger ps-3" />
              </FormGroup>
              <FormGroup>
                <Label for="email" className="textGray mb-1 ps-3">
                  Email Address
                </Label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="example@email.com"
                  className="form-control-lg w-100 borderLight rounded-4"
                />
                <ErrorMessage name="email" component="div" className="text-danger ps-3" />
              </FormGroup>
              <FormGroup>
                <Label className="textGray mb-1 ps-3">
                  Type of household member
                  <AiOutlineQuestionCircle
                    id="TooltipExample"
                    style={{ fontSize: "20px", marginTop: "-5px", marginLeft: "5px", cursor: "pointer" }}
                    onClick={toggleTooltip}
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="TooltipExample"
                    toggle={toggleTooltip}
                  >
                    Some random text for the tooltip.
                  </Tooltip>
                </Label>
                <div>
                  <input
                    type="checkbox"
                    id="toggle"
                    className="toggleCheckbox"
                    checked={!isHomeowner}
                    onChange={() => setIsHomeowner(!isHomeowner)}
                  />
                  <label htmlFor="toggle" className="toggleContainer">
                    <div>Homeowner</div>
                    <div>Renter/Tenant</div>
                  </label>
                </div>
              </FormGroup>
              <div className="mt-4 d-flex justify-content-between">
                <Button
                  type="button"
                  style={{
                    backgroundColor: "rgba(245, 245, 245, 1)",
                    fontWeight: 700,
                    fontSize: 16,
                    padding: "14px 20px",
                    borderColor: "unset",
                    border: "none",
                    color: "rgba(51, 51, 51, 1)",
                  }}
                  className="w-100 rounded-pill"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "rgba(1, 144, 210, 1)",
                    fontWeight: 700,
                    fontSize: 16,
                    padding: "14px 20px",
                    borderColor: "unset",
                  }}
                  className="w-100 rounded-pill"
                >
                  {member ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default HouseholdMemberForm;