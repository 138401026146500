import { useEffect, useState } from "react";
import { getMemberList, deleteMember } from "../../redux/action";
import { useSelector } from "react-redux";
import { CiEdit } from "react-icons/ci";
import { BsFillTrash3Fill } from "react-icons/bs";
import { Button, Modal, ModalBody } from "reactstrap";
import toast from "react-hot-toast";

const Members = ({ onEditMember, onDeleteMember }) => {
  const { token } = useSelector((state) => state);
  const [members, setMembers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const getMembers = async () => {
    try {
      const m = await getMemberList(token);
      setMembers(m);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    getMembers();
  }, [token]);

  const handleDeleteMember = async () => {
    try {
      await deleteMember(token, selectedMemberId);
      toast.success("Member deleted successfully.");
      setShowDeleteModal(false);
      getMembers();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      {(members || []).map((member) => (
        <div
          key={member.id}
          style={{
            borderColor: "rgba(197, 197, 197, 1)",
            borderWidth: "0.5px",
            borderRadius: "16px",
            backgroundColor: "rgba(245, 245, 245, 1)",
          }}
          className="d-flex mb-1 py-2 px-3 justify-content-between"
        >
          <div>
            <div style={{ fontSize: "14px" }}>{member.fullName}</div>
            <div style={{ fontSize: "12px" }}>{member.email}</div>
            <div style={{ fontSize: "12px" }}>{member.type}</div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <div className="mx-2 cursor-pointer" onClick={() => onEditMember(member)}>
              <CiEdit style={{ width: "20px", height: "20px" }} />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setSelectedMemberId(member.id);
                setShowDeleteModal(true);
              }}
            >
              <BsFillTrash3Fill style={{ color: "rgba(231, 76, 60, 1)" }} />
            </div>
          </div>
        </div>
      ))}

      {/* Delete Confirmation Modal */}
      <Modal centered isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)} contentClassName="rounded-5">
        <ModalBody className="p-4 text-center">
          <h4 className="font-32px f-7">Remove Member?</h4>
          <p className="f-5 textGray">Are you sure you want to remove this member? You cannot undo this.</p>
          <div className="d-flex justify-content-around mt-4">
            <Button
              style={{
                backgroundColor: "rgba(248, 248, 248, 1)",
                fontWeight: 700,
                fontSize: 16,
                padding: "14px 20px",
                border: "none",
                color: "black",
              }}
              className="rounded-pill"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "rgba(231, 76, 60, 1)",
                fontWeight: 700,
                fontSize: 16,
                padding: "14px 20px",
                borderColor: "unset",
                color: "white",
              }}
              className="rounded-pill"
              onClick={handleDeleteMember}
            >
              Remove
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Members;