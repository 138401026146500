import Speaker from "../assets/icons/speaker.png";
import Location from "../assets/icons/location.png";
import Projects from "../assets/icons/projects.png";
import Shield from "../assets/icons/shield.png";
import Calendar from "../assets/icons/calendar.png";
import News from "../assets/icons/News.png";

export const CATEGORIES = [
  {
    label: "General Announcements",
    icon: <img width="20" src={Speaker} alt="General Announcements" />,
    description:
      "For important updates, news, and general reminders that do not fit into other categories.",
  },
  {
    label: "Events & Socials",
    icon: <img width="20" src={Location} alt="Events & Socials" />,
    description:
      "For community events, social gatherings, activities, and related reminders.",
  },
  {
    label: "Projects & Maintenance",
    icon: <img width="20" src={Projects} alt="Projects & Maintenance" />,
    description:
      "For updates and reminders on construction, maintenance, and other project-related posts.",
  },
  {
    label: "Safety & Security",
    icon: <img width="20" src={Shield} alt="Safety & Security" />,
    description:
      "For crime alerts, safety tips, lookout posts, and related reminders.",
  },
  {
    label: "Meeting & Agendas",
    icon: <img width="20" src={Calendar} alt="Meeting & Agendas" />,
    description:
      "For announcements, reminders, and details about board meetings and agendas.",
  },
  {
    label: "Community News",
    icon: <img width="20" src={News} alt="Community News" />,
    description:
      "For broader neighborhood news, stories, and relevant information.",
  },
];