import React, { useState } from "react";
import { BsPaperclip } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { MdClose } from "react-icons/md";
import {
  Modal,
  ModalBody,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { sendSupportInquiry } from "../../redux/action";

const SupportModal = ({ show, onClose }) => {
  const [file, setFile] = useState(null);
  const { token } = useSelector((state) => state);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setFile(selectedFile);
    } else {
      toast.error("Please select an image file.");
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      subject: values.subject,
      message: values.message,
      // Ignoring the file for now
    };

    try {
      await sendSupportInquiry(token, data);
      toast.success("Inquiry sent successfully.");
      resetForm();
      setFile(null);
      onClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Modal centered isOpen={show} toggle={onClose} contentClassName="rounded-5">
      {/* Modal Header */}
      <div className="d-flex justify-content-between align-items-center pt-4 px-4 pb-0">
        <h4 className="font-32px f-7">Support</h4>
        <IoIosClose
          size={40}
          onClick={onClose}
          className="rounded-circle border bg-light p-2 cursor-pointer"
        />
      </div>

      {/* Scrollable Content (Including Button) */}
      <ModalBody className="p-4">
        <p style={{ textAlign: "center", marginTop: "-10px" }}>
          Invite your household members (spouse, renters/tenants, etc.) to ensure they stay informed and up-to-date about what is going on in your association.
        </p>
        <Formik
          initialValues={{ subject: "", message: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="mt-4">
              <FormGroup>
                <Label for="subject" className="textGray mb-1 ps-3">
                  Subject
                </Label>
                <div className="position-relative w-100">
                  <Field
                    name="subject"
                    type="text"
                    placeholder="Type here..."
                    className="form-control-lg w-100 borderLight rounded-4 custom-placeholder"
                  />
                  <ErrorMessage name="subject" component="div" className="text-danger ps-3" />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="message" className="textGray mb-1 ps-3">
                  Message
                </Label>
                <div className="position-relative w-100">
                  <Field
                    as="textarea"
                    name="message"
                    placeholder="Type here..."
                    rows={4}
                    className="form-control-lg w-100 borderLight rounded-4 custom-placeholder"
                  />
                  <ErrorMessage name="message" component="div" className="text-danger ps-3" />
                </div>
              </FormGroup>
              <div className="attachment-box text-center">
                <label htmlFor="file-upload" style={{ cursor: "pointer", display: "block", }}>
                  <p style={{ textAlign: "center", marginTop: "16px", fontSize: "16px", fontWeight: 700 }}>
                    <BsPaperclip style={{ fontSize: "24px" }} />
                    &nbsp;Attach a File
                  </p>
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
              {file && (
                <div className="file-preview mt-3 d-flex align-items-center justify-content-center">
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Preview"
                    style={{ maxWidth: "100px", maxHeight: "100px", marginRight: "10px" }}
                  />
                  <MdClose
                    size={24}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      backgroundColor: "rgba(1, 144, 210, 1)",
                      borderRadius: "50%",
                      color: "white",
                      padding: "5px",
                    }}
                    onClick={handleRemoveFile}
                  />
                </div>
              )}
              <div className="mt-4 d-flex justify-content-between">
                <Button
                  type="button"
                  style={{
                    backgroundColor: "rgba(245, 245, 245, 1)",
                    fontWeight: 700,
                    fontSize: 16,
                    padding: "14px 20px",
                    borderColor: "unset",
                    border: "none",
                    color: "rgba(51, 51, 51, 1)",
                  }}
                  className="w-100 rounded-pill"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "rgba(1, 144, 210, 1)",
                    fontWeight: 700,
                    fontSize: 16,
                    padding: "14px 20px",
                    borderColor: "unset",
                  }}
                  className="w-100 rounded-pill"
                >
                  Send
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default SupportModal;
