import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { 
  Modal, 
  ModalBody, 
  Button, 
  FormGroup, 
  Label, 
  Input 
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateNotificationSettings } from "../../redux/action";
import toast from "react-hot-toast";

const NotificationSettingsModal = ({ show, onClose }) => {
  
  const { user, token } = useSelector((state) => state);
  const [emailNotifications, setEmailNotifications] = useState(user?.emailNotificationsOn == "1" ? true : false);
  const [pushNotifications] = useState(user?.pushNotificationsOn);

  const handleSaveSettings = async () => {
    const settings = {
      emailNotificationsOn: emailNotifications,
      pushNotificationsnOn: pushNotifications == "1" ? true : false,
    };

    try {
      const response = await updateNotificationSettings(token, settings);
      toast.success(response?.message || "Settings updated successfully.");
      onClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Modal centered isOpen={show} toggle={onClose} contentClassName="rounded-5">
      {/* Modal Header */}
      <div className="d-flex justify-content-between align-items-center pt-4 px-4 pb-0">
        <h4 className="font-32px f-7">Notifications Settings</h4>
        <IoIosClose
          size={40}
          onClick={onClose}
          className="rounded-circle border bg-light p-2 cursor-pointer"
        />
      </div>

      {/* Scrollable Content */}
      <ModalBody className="p-4">
        {/* Posts Section */}
        <h5 className="mb-1 fw-bold">Posts</h5>
        <p className="text-muted mb-3">Receive alerts when new posts are added to Heynay</p>
        
        <FormGroup className="d-flex justify-content-between align-items-center mt-5 mb-5">
          <Label className="fw-normal m-0">Email</Label>
          <div className="form-check form-switch">
            <Input 
              type="checkbox" 
              role="switch"
              className="form-check-input"
              style={{borderRadius: "32px!important", height: "30px", width: "60px"}}
              checked={emailNotifications} 
              onChange={() => setEmailNotifications(!emailNotifications)} 
            />
          </div>
        </FormGroup>
        {/* Save Settings Button */}
        <div className="mt-4">
          <Button
            className="w-100 rounded-pill"
            style={{
              backgroundColor: "rgba(1, 144, 210, 1)",
              fontWeight: 700,
              fontSize: 16,
              padding: "14px 20px",
              borderColor: "unset"
            }}
            onClick={handleSaveSettings}
          >
            Save Settings
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NotificationSettingsModal;
