import { Button, Card, CardBody, CardHeader, Label } from "reactstrap";
import NormalInput from "../../components/inputs/NormalInput";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import forgotPasswordVectorArt from "../../assets/forgot-password-vector-art.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { RESET_INITIAL_VALUES } from "../../utils/initialValue";
import { RESET_VALIDATION_SCHEMA } from "../../utils/validation";
import Header from "../../components/globals/Header";
import { useState } from "react";
import { resetPassword } from "../../redux/action";
import { useParams } from "react-router-dom";
import StandardButton from "../../components/buttons/StandardButton";
import { CiLock } from "react-icons/ci";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import toast from 'react-hot-toast';


const ResetPassword = () => {
  const { theme } = useSelector((s) => s?.appSettings ?? {});
  const { token } = useParams(); 
  console.log("t", token);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleResetPassword = async (values, resetForm) => {
    setLoading(true);
    try {
      await resetPassword(values, token);
      resetForm();
      toast.success("Your password has been reset successfully.")
      navigate("/");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <><Header />
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <Card
          className={`${theme}  p-5 box-shadow center-box`}
          style={{ borderRadius: "48px" }}
        >
          <CardHeader
            tag={"div"}
            className="border-none  bg-transparent text-center"
          >
            <h2 className="f-7">Reset Your Password</h2>
            <small className="f-5 textGray">
              You can use the form below to change your password.
            </small>
          </CardHeader>
          <CardBody>
            <Formik
              initialValues={RESET_INITIAL_VALUES}
              validationSchema={RESET_VALIDATION_SCHEMA}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                console.log("Form submission triggered");
                handleResetPassword(values, resetForm);
                setSubmitting(false);
              }}
            >
              {({ handleSubmit }) => (
                 <Form onSubmit={handleSubmit}>
                 <div className="mb-3">
                   <Label for="password" className="textGray mb-1 ps-3">
                     Password
                   </Label>
                   <div className="position-relative w-100">
                     <CiLock
                       className="position-absolute lock-icon text-dark fs-5"
                       style={{
                         left: "15px",
                         top: "50%",
                         transform: "translateY(-50%)",
                       }}
                     />
 
                     <Field
                       name="password"
                       type={showPassword ? "text" : "password"}
                       placeholder="Minimum 8 characters..."
                       className="form-control w-100 borderLight inputPadding rounded-4 ps-5 custom-placeholder"
                       style={{ paddingLeft: "45px", fontSize: "18px", fontWeight: 400 }}
                     />
 
                     {/* Eye Icon for toggling password visibility */}
                     <div
                       onClick={togglePasswordVisibility}
                       style={{
                         position: "absolute",
                         right: "15px",
                         top: "50%",
                         transform: "translateY(-50%)",
                         cursor: "pointer",
                       }}
                     >
                       {showPassword ? (
                         <IoEyeOffOutline size={24} />
                       ) : (
                         <IoEyeOutline size={24} />
                       )}
                     </div>
                   </div>
                   <ErrorMessage
                     name="password"
                     component="div"
                     className="text-danger ps-3"
                   />
                 </div>
                 <div className="mb-3">
                   <Label for="confirmPassword" className="textGray mb-1 ps-3">
                    Confirm Password
                   </Label>
                   <div className="position-relative w-100">
                     <CiLock
                       className="position-absolute lock-icon text-dark fs-5"
                       style={{
                         left: "15px",
                         top: "50%",
                         transform: "translateY(-50%)",
                       }}
                     />
 
                     <Field
                       name="confirmPassword"
                       type={showPassword ? "text" : "password"}
                       placeholder="Minimum 8 characters..."
                       className="form-control w-100 borderLight inputPadding rounded-4 ps-5 custom-placeholder"
                       style={{ paddingLeft: "45px", fontSize: "18px", fontWeight: 400 }}
                     />
 
                     {/* Eye Icon for toggling password visibility */}
                     <div
                       onClick={togglePasswordVisibility}
                       style={{
                         position: "absolute",
                         right: "15px",
                         top: "50%",
                         transform: "translateY(-50%)",
                         cursor: "pointer",
                       }}
                     >
                       {showPassword ? (
                         <IoEyeOffOutline size={24} />
                       ) : (
                         <IoEyeOutline size={24} />
                       )}
                     </div>
                   </div>
                   <ErrorMessage
                     name="confirmPassword"
                     component="div"
                     className="text-danger ps-3"
                   />
                 </div>
                 <div className="w-100 mb-4 mt-5">
                   <StandardButton
                     type="submit"
                     className="btn-blue w-100 text-center border-0 inputPadding rounded-4"
                     style={{fontWeight: "700"}}
                     disabled={loading}
                   >
                    { "Reset"}
                   </StandardButton>
                 </div>
               </Form>
              )}</Formik>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ResetPassword;
