import * as Yup from "yup";
export const emailValidation = Yup.string()
  .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email")
  .required("Email required");

const passwordValidation = Yup.string()
  .min(8, "Password must be at least 8 characters")
  .matches(
    /^(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/,
    "Password must contain at least one number, and one special character"
  )
  .required("Password required");

const confirmValidation = Yup.string()
.required('Confirm password is required')
.test('passwords-match', 'Passwords must match', function (value) {
  return value === this.parent.password;
})

export const SIGNIN_VALIDATION_SCHEMA = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
});

export const CREATE_POST_SCHEMA = Yup.object().shape({
  heading: Yup.string().required("Heading is required"),
  content: Yup.string().required("Content is required"),
});


export const FORGOT_VALIDATION_SCHEMA = Yup.object().shape({
  email: emailValidation,
});

export const RESET_VALIDATION_SCHEMA = Yup.object({
  password: passwordValidation,
  confirmPassword: confirmValidation
});