import React, { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import {
  Col,
  Row,
  Modal,
  Label,
  Input,
  Button,
  Container,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";
import AccountSettingsModal from "./AccountSettingsModal";
import NotificationSettingsModal from "./NotificationSettingsModal";
import ManageHouseholdModal from "./ManageHouseholdModal";

const SettingsModal = ({ show, onClose }) => {
  const [showAccountSettingsModal, setShowAccountSettingsModal] = useState(false);
  const [showNotificationSettingsModal, setShowNotificationSettingsModal] = useState(false);
  const [showManageHouseholdModal, setShowManageHouseholdModal] = useState(false);
  return (
    <>
    <Modal 
    centered={true}
    isOpen={show} contentClassName="rounded-5"
    toggle={onClose}>
          {/* Modal Header */}
          <div className="d-flex justify-content-between align-items-center pt-4 px-4 pb-0">
            <span>
            <h4 className="font-32px f-7">Settings</h4>
            </span>
             <span>
                      <IoIosClose
                        size={40}
                        onClick={onClose}
                        className="rounded-circle border bg-light p-2 cursor-pointer"        
                      />
                    </span>
          </div>

          {/* Modal Body */}
          <ModalBody className="p-4">
            <ul className="list-group">
              <li className="menu-item d-flex justify-content-between align-items-center">
                <a className="dropdown-item" href="#" onClick={() => setShowAccountSettingsModal(true)}>Account Settings <BsChevronRight
                                style={{
                                  fontSize: "15px",
                                  position: "absolute",
                                  right: "0px",
                                  top: "5px"
                                }}
                              />
                              </a>
          
              </li>
              <li className="menu-item d-flex justify-content-between align-items-center">
              <a className="dropdown-item" href="#" onClick={() => setShowNotificationSettingsModal(true)}>Notifications Settings <BsChevronRight
                                style={{
                                  fontSize: "15px",
                                  position: "absolute",
                                  right: "0px",
                                  top: "5px"
                                }}
                              />
                              </a>
              </li>
              <li className="menu-item d-flex justify-content-between align-items-center">
              <a className="dropdown-item" href="#" onClick={() => setShowManageHouseholdModal(true)}>Manage Household Members <BsChevronRight
                                style={{
                                  fontSize: "15px",
                                  position: "absolute",
                                  right: "0px",
                                  top: "5px"
                                }}
                              />
                              </a>
              </li>
            </ul>
          </ModalBody>
        </Modal>
        {showAccountSettingsModal && <AccountSettingsModal show={showAccountSettingsModal} onClose={()=> setShowAccountSettingsModal(false)}></AccountSettingsModal>}
        {showNotificationSettingsModal && <NotificationSettingsModal show={showNotificationSettingsModal} onClose={() => setShowNotificationSettingsModal(false)}></NotificationSettingsModal>}
        {showManageHouseholdModal && <ManageHouseholdModal show={showManageHouseholdModal} onClose={() => setShowManageHouseholdModal(false)}></ManageHouseholdModal>}
        </>
  )
};

export default SettingsModal;
