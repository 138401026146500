import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import {
  Modal,
  ModalBody,
  Button,
} from "reactstrap";

const WhereIsMyCodeModal = ({show, onClose}) => {
  return (
    <Modal centered isOpen={show} toggle={onClose} contentClassName="rounded-5">
      {/* Modal Header */}
      <div className="d-flex justify-content-between align-items-center pt-4 px-4 pb-0">
      </div>

      {/* Scrollable Content (Including Button) */}
      <ModalBody className="p-4">
        <div>
          <h2 className="text-center mb-3" style={{ fontSize: "16px", fontWeight: 700 }}>Where is my code?</h2>
        </div>
        <div className="text-center">
        Your personal account verification code was sent to the email address provided by your association. Please check your inbox for an email from Heynay with the subject line: Heynay Invitation 🎉
        </div>
        <div className="text-center mt-3">
        If you are unable to find the email or would like to register your HOA on Heynay, please reach out to us at support@heynay.com.
        </div>
        <div className="mt-4">
          <Button
          onClick={onClose}
            style={{
              backgroundColor: "rgba(1, 144, 210, 1)",
              fontWeight: 700,
              fontSize: 16,
              padding: "14px 20px",
              borderColor: "unset"
            }}
            className="w-100 rounded-pill"
          >
            Okay
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default WhereIsMyCodeModal;