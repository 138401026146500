import React, { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import {
  Col,
  Row,
  Modal,
  Label,
  Input,
  Button,
  Container,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";

const AboutModal = ({ show, onClose }) => {

  return (
    <>
    <Modal 
    centered={true}
    isOpen={show} contentClassName="rounded-5"
    toggle={onClose}>
          {/* Modal Header */}
          <div className="d-flex justify-content-between align-items-center pt-4 px-4 pb-0">
            <span>
            <h4 className="font-32px f-7">About</h4>
            </span>
             <span>
                      <IoIosClose
                        size={40}
                        onClick={onClose}
                        className="rounded-circle border bg-light p-2 cursor-pointer"    
                      />
                    </span>
          </div>

          {/* Modal Body */}
          <ModalBody className="p-4">
            <ul className="list-group">
              <li className="menu-item d-flex justify-content-between align-items-center">
                <a className="dropdown-item" href="https://www.heynay.com/privacy" target="_blank" rel="noreferrer">Privacy Policy <BsChevronRight
                                style={{
                                  fontSize: "15px",
                                  position: "absolute",
                                  right: "0px",
                                  top: "5px"
                                }}
                              />
                              </a>
          
              </li>
            </ul>
          </ModalBody>
        </Modal>
        </>
  )
};

export default AboutModal;
