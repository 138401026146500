import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { Modal, ModalBody, Button } from "reactstrap";
import Members from "./Members";
import { BsPlusCircle } from "react-icons/bs";
import HouseholdMemberForm from "./HouseholdMemberForm";
import { getMemberList } from "../../redux/action";
import { useSelector } from "react-redux";

const ManageHouseholdModal = ({ show, onClose }) => {
  const [showMemberForm, setShowMemberForm] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const { token } = useSelector((state) => state);
  const [members, setMembers] = useState([]);

  const getMembers = async () => {
    try {
      const m = await getMemberList(token);
      setMembers(m);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  const handleInviteMember = () => {
    setSelectedMember(null);
    setShowMemberForm(true);
  };

  const handleEditMember = (member) => {
    setSelectedMember(member);
    setShowMemberForm(true);
  };

  const handleCloseMemberForm = () => {
    setShowMemberForm(false);
    getMembers();
  };

  return (
    <>
      <Modal centered isOpen={show} toggle={onClose} contentClassName="rounded-5">
        {/* Modal Header */}
        <div className="d-flex justify-content-between align-items-center pt-4 px-4 pb-0">
          <h4 className="font-32px f-7">Manage Household Members</h4>
          <IoIosClose
            size={40}
            onClick={onClose}
            className="rounded-circle border bg-light p-2 cursor-pointer"
          />
        </div>

        {/* Scrollable Content (Including Button) */}
        <ModalBody className="p-4">
          <Members onEditMember={handleEditMember} />
          <div className="mt-4">
            <Button
              style={{
                borderColor: "rgba(1, 144, 210, 1)",
                borderStyle: "dashed",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontSize: 16,
                padding: "14px 20px",
                color: "rgba(1, 144, 210, 1)",
                borderRadius: "16px!important",
              }}
              className="w-100 rounded-pill"
              onClick={handleInviteMember}
            >
              <BsPlusCircle
                style={{ color: "rgba(1, 144, 210, 1)", marginRight: "5px", marginBottom: "3px" }}
              />
              Invite Household Member
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <HouseholdMemberForm
        show={showMemberForm}
        onClose={handleCloseMemberForm}
        member={selectedMember}
        refreshMembers={getMembers}
      />
    </>
  );
};

export default ManageHouseholdModal;
