import {
  Card,
  Label,
  Input,
  CardBody,
  FormGroup,
  CardHeader,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { CiLock } from "react-icons/ci";
import { login, getProfile } from "../../redux/action";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { SIGNIN_INITIAL_VALUES } from "../../utils/initialValue";
import { SIGNIN_VALIDATION_SCHEMA } from "../../utils/validation";
import StandardButton from "../../components/buttons/StandardButton";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import Header from "../../components/globals/Header";

const Login = () => {
  const { theme } = useSelector((s) => s?.appSettings ?? {});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const token = useSelector((state) => state.token);

  useEffect(() => {
    console.log()
    if (token) {
      navigate("/homefeed")
    }
  }, [token]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleLoginSubmit = async (values) => {
    console.log("Submitting values:", values);
    setLoading(true);
    try {
      const data = await dispatch(login(values));
      console.log("Login successful, data:", data);
      await dispatch(getProfile(data.access_token))
      navigate("/homefeed");
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <> <Header />
    <div className="d-flex align-items-center justify-content-center vh-100">
      <Formik
        initialValues={SIGNIN_INITIAL_VALUES}
        validationSchema={SIGNIN_VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting }) => {
          console.log("Form submission triggered");
          handleLoginSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit }) => (
          <Card
            className={`${theme} p-5 box-shadow  center-box`}
            style={{ borderRadius: "48px" }}
          >
            <CardHeader
              tag={"div"}
              className="border-none bg-transparent text-center"
            >
              <h2 className="f-7">Login to your account</h2>
              <small className="f-5 textGray">Please enter your login details</small>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <Label for="" className="textGray mb-1 ps-3">
                    Email Address
                  </Label>
                  <Field
                    name="email"
                    type="email"
                    placeholder="example@email.com"
                    className="form-control-lg w-100 borderLight inputPadding rounded-4"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger ps-3"
                  />
                </div>
                <div className="mb-3">
                  <Label for="password" className="textGray mb-1 ps-3">
                    Password
                  </Label>
                  <div className="position-relative w-100">
                    <CiLock
                      className="position-absolute lock-icon text-dark fs-5"
                      style={{
                        left: "15px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />

                    <Field
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Minimum 8 characters..."
                      className="form-control w-100 borderLight inputPadding rounded-4 ps-5 custom-placeholder"
                      style={{ paddingLeft: "45px", fontSize: "18px", fontWeight: 400 }}
                    />

                    {/* Eye Icon for toggling password visibility */}
                    <div
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? (
                        <IoEyeOffOutline size={24} />
                      ) : (
                        <IoEyeOutline size={24} />
                      )}
                    </div>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-danger ps-3"
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="small">
                    <FormGroup className="mb-0" check inline>
                      <Input className="border-2 login" type="checkbox" />
                      <Label className="color-blue mb-0" check>
                        {" "}
                        Remember Me
                      </Label>
                    </FormGroup>
                  </span>
                  <Link className="small" to="/forgot-password" style={{textDecoration: "none", color: "rgba(51, 51, 51, 1)", cursor: "pointer"}}><span style={{fontWeight: 500}}>Forgot Password?</span></Link>
                </div>
                <div className="w-100 mb-4 mt-5">
                  <StandardButton
                    type="submit"
                    className="btn-blue w-100 text-center border-0 inputPadding rounded-4"
                    style={{fontWeight: "700"}}
                    disabled={loading}
                  >
                    {loading ? "Logging in..." : "Login"}
                  </StandardButton>
                </div>
                <div className="text-center w-100 mb-2">
                  <Link to="#" className="f-5 color-dark_gray" style={{textDecoration: "none"}}><small className="f-5 color-dark_gray">
                    Don’t have an account?
                  </small> </Link>
                </div>
                <StandardButton
                  type="button"
                  onClick={() => navigate("/setup-account")}
                  className="btn-grey border-Color w-100 text-center inputPadding rounded-4"
                  style={{fontWeight: "700"}}
                  disabled={loading}
                >
                  Set Up Account
                </StandardButton>
              </Form>
            </CardBody>
          </Card>
        )}
      </Formik>
    </div>
    </>
  );
};

export default Login;
