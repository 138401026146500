import React from "react";
import "./CreatePostModal.css";
import { IoIosClose } from "react-icons/io";
import { Modal, ModalBody } from "reactstrap";

const CreatePostModal = ({
  toggle,
  isOpen,
  maxWidth,
  children,
  bodyClass,
  headerClass,
  headerTitle,
  contentClassName,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName={`custom-modal rounded-5 ${contentClassName}`}
      style={{ maxWidth }}
    >
      <div
        className={`${headerClass} d-flex align-items-center justify-content-between pt-4 px-4 pb-0 bg-white overflow-hidden rounded-5 `}
      >
        <span>
          <h3 className="font-32px f-7">{headerTitle}</h3>
        </span>
        <span>
          <IoIosClose
            size={40}
            onClick={toggle}
            className="rounded-circle border bg-light p-2 cursor-pointer"        
          />
        </span>
      </div>
      <ModalBody className={`${bodyClass} p-4 rounded-5`}>{children}</ModalBody>
    </Modal>
  );
};

export default CreatePostModal;
