import React, { useState, useRef } from "react";
import { BsUpload } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import {
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { CiLock } from "react-icons/ci";
import { Field, Formik, ErrorMessage } from "formik";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { IoCloseCircleSharp } from "react-icons/io5";
import { getProfile, updateProfile, uploadProfileImage, changePassword, deleteUser } from "../../redux/action";
import toast from "react-hot-toast";
import { RESET_INITIAL_VALUES } from "../../utils/initialValue";
import { emailValidation, RESET_VALIDATION_SCHEMA } from "../../utils/validation";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const AccountSettingsModal = ({ show, onClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { user, token } = useSelector((state) => state);
  const [image, setProfileImage] = useState(
    user?.profileImage ? user?.profileImage : ""
  );
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isUpdatingImage, setIsUpdatingImage] = useState(false);
  const [isImageCleared, setIsImageCleared] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fileInputRef = useRef(null);

  const clearProfileImage = () => {
    setProfileImage(null);
    setFile(null);
    setIsImageCleared(true);
  };

  const handleButtonClick = () => {
    fileInputRef.current.value = "";
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile(event.target.files[0]);
      const url = URL.createObjectURL(event.target.files[0]);
      setProfileImage(url);
    }
  };

  const updateImage = async () => {
    setIsUpdatingImage(true);
    let clearImage = false;
    if (!file && isImageCleared) {
      clearImage = true;
    }
    if (clearImage) {
      const r = await updateProfile(token, {
        clearProfileImage: clearImage,
      });
      toast.success(r?.message)
      await dispatch(getProfile(token));
    }
    if (file) {
      var formdata = new FormData();
      formdata.append('file', file);
      const response = await uploadProfileImage(token, formdata);
      toast.success(response?.message)
      await dispatch(getProfile(token));
    }
    setIsUpdatingImage(false);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteUser(token);
      toast.success("Account deleted successfully.");
      // Log out the user and redirect to the homepage
      // Assuming you have a logout action
      dispatch({ type: "LOGOUT" });
      navigate("/");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    email: emailValidation,
  });

  return (
    <>
      <Modal centered isOpen={show} toggle={onClose} contentClassName="rounded-5">
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          style={{ "visibility": "hidden" }}
          onChange={handleFileChange}
        />
        {/* Modal Header */}
        <div className="d-flex justify-content-between align-items-center pt-4 px-4 pb-0">
          <h4 className="font-32px f-7">Account Settings</h4>
          <IoIosClose
            size={40}
            onClick={onClose}
            className="rounded-circle border bg-light p-2 cursor-pointer"
          />
        </div>

        {/* Scrollable Content (Including Button) */}
        <ModalBody className="p-4">
          <div>
            <h2 style={{ fontSize: "16px", fontWeight: 700 }}>Edit Basic Info</h2>
            <div className="d-flex align-items-center justify-content-center">
              {image && (
                <>
                  <img
                    onClick={handleButtonClick}
                    height={150}
                    width={150}
                    style={{ borderRadius: "75px", cursor: "pointer" }}
                    src={image}
                  />
                  <div
                    onClick={clearProfileImage}
                    style={{
                      position: "absolute",
                      top: "41px",
                      right: "31%",
                      cursor: "pointer"
                    }}
                  >
                    <IoCloseCircleSharp
                      style={{ fontSize: "35px", color: "rgb(1, 144, 210)" }}
                    />
                  </div>
                </>
              )}
              {!image && (
                <div
                  onClick={handleButtonClick}
                  style={{
                    width: 140,
                    height: 140,
                    borderColor: "rgba(1, 144, 210, 1)",
                    borderWidth: 1,
                    borderStyle: "dashed",
                    borderRadius: 70,
                    backgroundColor: "rgba(248, 248, 248, 1)",
                    cursor: "pointer"
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "45px",
                      fontSize: "20px"
                    }}
                  >
                    <BsUpload style={{ color: "rgba(1, 144, 210, 1)" }} />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      fontSize: 13,
                      color: "rgba(1, 144, 210, 1)"
                    }}
                  >
                    Upload a photo
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <Button
              disabled={isUpdatingImage}
              onClick={updateImage}
              style={{
                backgroundColor: "rgba(1, 144, 210, 1)",
                fontWeight: 700,
                fontSize: 16,
                padding: "14px 20px",
                borderColor: "unset"
              }}
              className="w-100 rounded-pill"
            >
              {isUpdatingImage ? "Updating..." : "Update Image"}
            </Button>
          </div>
          <Formik
            initialValues={{
              fullName: user?.fullName || '',
              email: user?.email || ''
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              try {
                const params = {
                  ...values,
                  clearProfileImage: false,
                }
                const response = await updateProfile(token, params);
                toast.success(response?.message);
                await dispatch(getProfile(token));
              } catch (error) {
                toast.error(error.message);
              }
            }}
          >
            {({ handleSubmit }) => (
              <Form className="mt-4" onSubmit={handleSubmit}>
                {/* Personal Info */}
                <FormGroup>
                  <Label for="fullName" className="textGray mb-1 ps-3">
                    Full Name
                  </Label>
                  <Field
                    type="text"
                    id="fullName"
                    name="fullName"
                    placeholder="John Doe"
                    className="form-control-lg w-100 borderLight rounded-4"
                  />
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    className="text-danger ps-3"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email" className="textGray mb-1 ps-3">
                    Email
                  </Label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="example@email.com"
                    className="form-control-lg w-100 borderLight rounded-4"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger ps-3"
                  />
                </FormGroup>
                <div className="mt-4">
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "rgba(1, 144, 210, 1)",
                      fontWeight: 700,
                      fontSize: 16,
                      padding: "14px 20px",
                      borderColor: "unset"
                    }}
                    className="w-100 rounded-pill"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <Formik
            initialValues={RESET_INITIAL_VALUES}
            validationSchema={RESET_VALIDATION_SCHEMA}
            onSubmit={async (values, { resetForm }) => {
              if (values.password !== values.confirmPassword) {
                toast.error("Passwords do not match");
                return;
              }
              try {
                const response = await changePassword(token, { newPassword: values.password });
                toast.success(response?.message);
                resetForm();
              } catch (error) {
                toast.error(error.message);
              }
            }}
          >
            {({ handleSubmit }) => (
              <Form className="mt-4" onSubmit={handleSubmit}>
                <h2 style={{ fontSize: "16px", fontWeight: 700 }}>Change Password</h2>
                <FormGroup>
                  <Label for="password" className="textGray mb-1 ps-3">
                    New Password
                  </Label>
                  <div className="position-relative w-100">
                    <CiLock
                      className="position-absolute lock-icon text-dark fs-5"
                      style={{
                        left: "15px",
                        top: "50%",
                        transform: "translateY(-50%)"
                      }}
                    />
                    <Field
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Minimum 8 characters..."
                      className="form-control-lg w-100 borderLight rounded-4 ps-5 custom-placeholder"
                      style={{ paddingLeft: "45px" }}
                    />
                    {/* Eye Icon for toggling password visibility */}
                    <div
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer"
                      }}
                    >
                      {showPassword ? (
                        <IoEyeOffOutline size={24} />
                      ) : (
                        <IoEyeOutline size={24} />
                      )}
                    </div>{" "}
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-danger ps-3"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="confirmPassword" className="textGray mb-1 ps-3">
                    New Confirm Password
                  </Label>
                  <div className="position-relative w-100">
                    <CiLock
                      className="position-absolute lock-icon text-dark fs-5"
                      style={{
                        left: "15px",
                        top: "50%",
                        transform: "translateY(-50%)"
                      }}
                    />
                    <Field
                      name="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      placeholder="Minimum 8 characters..."
                      className="form-control-lg w-100 borderLight rounded-4 ps-5 custom-placeholder"
                      style={{ paddingLeft: "45px" }}
                    />
                    {/* Eye Icon for toggling password visibility */}
                    <div
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer"
                      }}
                    >
                      {showPassword ? (
                        <IoEyeOffOutline size={24} />
                      ) : (
                        <IoEyeOutline size={24} />
                      )}
                    </div>{" "}
                  </div>
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-danger ps-3"
                  />
                </FormGroup>
                <div className="mt-4">
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "rgba(1, 144, 210, 1)",
                      fontWeight: 700,
                      fontSize: 16,
                      padding: "14px 20px",
                      borderColor: "unset"
                    }}
                    className="w-100 rounded-pill"
                  >
                    Change Password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="mt-4 mb-4 d-flex justify-content-between align-items-center">
            <a
              style={{
                color: " rgb(231, 76, 60)",
                width: "100%",
                textAlign: "center",
                textDecoration: "none",
                fontWeight: 700
              }}
              href="#"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete Account
            </a>
          </div>
        </ModalBody>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal centered isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)} contentClassName="rounded-5">
        <ModalBody className="p-4 text-center">
          <h4 className="font-32px f-7">Are you sure?</h4>
          <p className="f-5 textGray">Do you really want to delete your account? This process cannot be undone.</p>
          <div className="d-flex justify-content-around mt-4">
            <Button
              style={{
                backgroundColor: "rgba(1, 144, 210, 1)",
                fontWeight: 700,
                fontSize: 16,
                padding: "14px 20px",
                borderColor: "unset"
              }}
              className="rounded-pill"
              onClick={handleDeleteAccount}
            >
              Yes, Delete
            </Button>
            <Button
              style={{
                backgroundColor: "rgba(248, 248, 248, 1)",
                fontWeight: 700,
                fontSize: 16,
                padding: "14px 20px",
                borderColor: "unset"
              }}
              className="rounded-pill"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AccountSettingsModal;