import { Button, Card, CardBody, CardHeader, Label } from "reactstrap";
import NormalInput from "../../components/inputs/NormalInput";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import forgotPasswordVectorArt from "../../assets/forgot-password-vector-art.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FORGOT_INITIAL_VALUES } from "../../utils/initialValue";
import { FORGOT_VALIDATION_SCHEMA } from "../../utils/validation";
import Header from "../../components/globals/Header";
import { useState } from "react";
import { forgotPassword } from "../../redux/action";
import toast from 'react-hot-toast';


const ForgotPassword = () => {
  const { theme } = useSelector((s) => s?.appSettings ?? {});
  const [loading, setLoading] = useState(false);

  const navigateTo = useNavigate();

  const handleForgotPassword = async (values, resetForm) => {
    setLoading(true);
    try {
      await forgotPassword(values);
      resetForm();
      toast.success('Your reset password email is on its way!')
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <><Header />
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <Card
          className={`${theme}  p-5 box-shadow center-box`}
          style={{ borderRadius: "48px" }}
        >
          <CardHeader
            tag={"div"}
            className="border-none  bg-transparent text-center"
          >
            <h2 className="f-7">Forgot Your Password?</h2>
            <small className="f-5 textGray">
              No worries! Enter your email address, and we'll send you
              instructions to reset your password.
            </small>
          </CardHeader>
          <CardBody>
            <div className="text-center mb-2">
              <img
                alt="forgot-password"
                height={150}
                width={200}
                src={forgotPasswordVectorArt}
                className="object-fit-contain"
              />
            </div>
            <Formik
              initialValues={FORGOT_INITIAL_VALUES}
              validationSchema={FORGOT_VALIDATION_SCHEMA}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                console.log("Form submission triggered");
                handleForgotPassword(values, resetForm);
                setSubmitting(false);
              }}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <Label for="" className="textGray mb-1 ps-3">
                      Email Address
                    </Label>
                    <Field
                      name="email"
                      type="email"
                      placeholder="example@email.com"
                      className="form-control w-100 borderLight inputPadding rounded-4"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger ps-3"
                    />
                  </div>
                  <div className="d-flex align-items-center gap-2  justify-content-center mt-5">
                    <Button
                      className="btn btn-grey text-black full-width py-3  f-5 border-none"
                      onClick={() => navigateTo("/")}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={loading}
                      className="btn btn-blue f-5 border-none full-width py-3 ">Submit</Button>
                  </div>
                </Form>
              )}</Formik>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ForgotPassword;
