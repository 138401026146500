import { BASE_URL } from "../../utils/GlobalUtils";

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const ADD_TOKEN = 'ADD_TOKEN';

export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE';
export const GET_POST_LIST_SUCCESS = 'GET_POST_LIST_SUCCESS';
export const GET_POST_LIST_FAILURE = 'GET_POST_LIST_FAILURE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const LOG_OUT = 'LOG_OUT';

export const addToken = (token) => ({
  type: ADD_TOKEN,
  payload: token,
});

export const login = (credentials) => {
  return async (dispatch) => {
    dispatch({ type: 'LOGIN_REQUEST' });
    try {      
      const response = await fetch(`${BASE_URL}auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }
      const data = await response.json();      
      const accessToken = data.access_token;
      dispatch({ type: LOGIN_SUCCESS, payload: { accessToken } });
      dispatch(addToken(accessToken));
      return data;
    } catch (error) {
      console.error("Login failed:", error.message);
      throw error;
    }
  };
};

export const getProfile = (token) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BASE_URL}users/profile`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const error = await response.json();
        dispatch({ type: GET_PROFILE_FAILURE, payload: error });
        throw new Error(error.message);
      } 
      const data = await response.json();     
      dispatch({ type: GET_PROFILE_SUCCESS, payload: { user: data } });
      return data;
    } catch (error) {
      dispatch({ type: GET_PROFILE_FAILURE, payload: error });
      throw new Error(error.message);
    }
  }
}

export const createPost = (formData,token) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BASE_URL}posts`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const error = await response.json();
        dispatch({ type: CREATE_POST_FAILURE, payload: error });
        throw new Error(error.message);
      }
      const data = await response.json();      
      // dispatch({ type: CREATE_POST_SUCCESS, payload: data });
      return data;
    } catch (error) {
      console.error('Error creating post:', error);
      throw error;
    }
  };
};
export const updatePost = async(postId,data,token) => {      
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      
      const raw = JSON.stringify({
        "label": data.label,
        "content": data.content,
        "title": data.title,
        "isPinned": true
      });
      
      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      
    await  fetch(`${BASE_URL}posts/${postId}`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    } catch (error) {
      console.error('Error creating post:', error);
      throw error;
    }  
}
export const getPostList = (token,category,sortBy) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BASE_URL}posts/list?label=${category}&sortOrder?=${sortBy}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });      
      if (!response.ok) {
        const error = await response.json();
        dispatch({ type: GET_POST_LIST_FAILURE, payload: error });
        throw new Error(error.message);
      }
      const data = await response.json();      
      // dispatch({ type: GET_POST_LIST_SUCCESS, payload: data });
      return data;
    } catch (error) {      
      dispatch({ type: GET_POST_LIST_FAILURE, payload: error.message });
      throw error;
    }
  };
};

export const deletePost = async (postId,token)=>{
  try {
    const response = await fetch(`${BASE_URL}posts/${postId}`,{
      method: 'DELETE',
      headers:{
        'Authorization': `Bearer ${token}`,
      }
    });
    // const data = await response.json();   
    // return data;
  }catch(error){
    console.error('Error deleting post:', error);
    throw error;
  }
}

export const appSettings=(payload)=>{
  return {type:"SETTINGS",payload:payload}
}

export const getMemberList = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}users/list-members`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data);
    }
    return data;
  } catch(error) {
    console.error("Error getting members");
    throw error;
  }
}

export const forgotPassword = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}auth/forget-password`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
      },
      body: JSON.stringify(email)
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch(error) {
    console.error("Error forgotPassword");
    throw error;
  }
}

export const resetPassword = async (creds, token) => {
  try {
    const response = await fetch(`${BASE_URL}auth/reset-password`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
      },
      body: JSON.stringify({
        ...creds,
        token
      })
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch(error) {
    console.error("Error resetPassword");
    throw error;
  }
}

export const updateProfile = async (token, params) => {
  try {
    const response = await fetch(`${BASE_URL}users/profile`, {
      method: "PUT",
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch(error) {
    console.error("Error getting members");
    throw error;
  }
}

export const uploadProfileImage = async (token, formData) => {
  try {
    const response = await fetch(`${BASE_URL}users/upload-profile-image`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    console.error('Error uploading profile image:', error);
    throw error;
  }
};

export const changePassword = async (token, passwordData) => {
  try {
    const response = await fetch(`${BASE_URL}users/change-password`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(passwordData),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};

export const deleteUser = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}users/delete-account`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export const updateNotificationSettings = async (token, settings) => {
  try {
    const response = await fetch(`${BASE_URL}users/update-settings`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(settings),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    console.error('Error updating notification settings:', error);
    throw error;
  }
};

export const inviteMember = async (token, memberData) => {
  try {
    const response = await fetch(`${BASE_URL}users/add-member`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(memberData),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    console.error('Error inviting member:', error);
    throw error;
  }
};

export const updateMember = async (token, memberId, memberData) => {
  try {
    const response = await fetch(`${BASE_URL}users/update-member/${memberId}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(memberData),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    console.error('Error updating member:', error);
    throw error;
  }
};

export const deleteMember = async (token, memberId) => {
  try {
    const response = await fetch(`${BASE_URL}users/delete-member/${memberId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    console.error('Error deleting member:', error);
    throw error;
  }
};

export const sendSupportInquiry = async (token, data) => {
  try {
    const response = await fetch(`${BASE_URL}system/inquiry`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(responseData.message);
    }
    return responseData;
  } catch (error) {
    console.error('Error sending support inquiry:', error);
    throw error;
  }
};